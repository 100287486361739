import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING } from '@/styles/tokens/fonts';

import {
  NOTIFICATION_THEME_BY_SEVERITY,
  NotificationSeverity,
} from '../constants';

export type AlertSize = 'sm' | 'lg';
export type AlertProps = React.PropsWithChildren<{
  severity: NotificationSeverity;
  size: AlertSize;
  transparent?: boolean;
  sx?: SxProps<Theme>;
  handleDismiss?: () => void;
}>;

interface AlertPadding {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

const themePaddingBySize: Record<AlertSize, AlertPadding> = {
  sm: {
    top: 1,
    bottom: 1,
    left: 1.5,
    right: 0.5,
  },
  lg: {
    top: 2,
    bottom: 2,
    left: 2,
    right: 3,
  },
};

export const Alert = React.forwardRef(
  (
    {
      severity,
      size,
      children,
      transparent,
      handleDismiss,
      sx = {},
    }: AlertProps,
    ref
  ) => {
    const theme = NOTIFICATION_THEME_BY_SEVERITY[severity];
    const padding = themePaddingBySize[size];
    const fontSizing = size === 'sm' ? FONT_SIZING.xs : FONT_SIZING.sm;
    const themeStyleProps = {
      borderLeft: `solid 4px ${theme.border}`,
      bgcolor: transparent ? 'transparent' : theme.background,
    };

    return (
      <Box
        ref={ref}
        sx={{
          pt: padding.top,
          pb: padding.bottom,
          pl: padding.left,
          pr: padding.right,
          ...themeStyleProps,
          ...sx,
        }}
      >
        <Stack gap={1} direction="row" alignItems="center">
          <Typography
            {...fontSizing}
            component="div"
            variant="subtitle1"
            color={theme.text}
          >
            {children}
          </Typography>
          {handleDismiss && (
            <Button
              variant="transparent"
              size="xs"
              onClick={handleDismiss}
              sx={{
                padding: 0,
                '&:hover': { backgroundColor: 'initial' },
              }}
            >
              <XCloseIcon sx={{ color: COLORS.GRAY[500] }} />
            </Button>
          )}
        </Stack>
      </Box>
    );
  }
);

Alert.displayName = 'Alert';
