import { createTheme, Theme, ThemeProvider } from '@mui/material';
import { noop } from 'lodash';
import React, { createContext, useMemo, useState } from 'react';

import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { diagnostics } from '@/utils/diagnostics';

import { COMMON_THEME_PROPERTIES } from './common';
import {
  BRAND,
  getThemeFromTenantBranding,
  makeThemeFromBrand,
} from './themes.utils';

const initialTheme = createTheme(
  COMMON_THEME_PROPERTIES,
  makeThemeFromBrand(BRAND)
);

interface CustomerThemeContextType {
  setTheme: (arg: Theme) => void;
  customerTheme: Theme;
}

export const CustomerThemeContext = createContext<CustomerThemeContextType>({
  setTheme: noop,
  customerTheme: initialTheme,
});

export function CustomerThemeProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const { branding, subBrandsById, activeSubBrandId } =
    useTenantDetailsContext();
  const [themeState, setTheme] = useState<Theme>(initialTheme);

  const customerTheme = useMemo(() => {
    // this can happen during a first pass when tenant data is still populating
    if (!branding) return;

    // if there's no active subbrand, reset to the primary branding -- this will happen when the activeSubBrandId is
    // set to null or in the initial case
    if (!activeSubBrandId) {
      return getThemeFromTenantBranding(branding);
    }

    const subBrand = subBrandsById[activeSubBrandId];
    if (!subBrand) {
      diagnostics.error(
        `attempting to render subbrand with id ${activeSubBrandId} but it does not exist`,
        new Error(
          `attempting to render subbrand with id ${activeSubBrandId} but it does not exist`
        ),
        {
          activeSubBrandId,
          availableIds: JSON.stringify(Object.keys(subBrandsById)),
        }
      );
      return;
    }

    return getThemeFromTenantBranding(subBrand);
  }, [activeSubBrandId, branding, subBrandsById]);

  const theme = customerTheme ?? themeState;

  return (
    <CustomerThemeContext.Provider value={{ setTheme, customerTheme: theme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomerThemeContext.Provider>
  );
}
