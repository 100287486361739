import { Fade, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { CSSProperties, FC } from 'react';
import React from 'react';

import { SIDEBAR_ANIMATION_TIMING_MS } from '@/components/architecture/Sidebar/sidebarConstants';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { LuminaryTheme } from '@/styles/themes/themes.utils';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getPublicImageUrl } from '@/utils/staticFiles';
import { getTenant } from '@/utils/tenantUtils';

export enum LogomarkSize {
  Square = 'square',
  Wide = 'wide',
}
/**
 * primary: dark text, light background (default)
 * secondary: light text, dark background
 */
export enum LogomarkVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

type ImgProps = React.ComponentProps<'img'>;
type LuminaryLogomarkProps = {
  size: LogomarkSize;
  variant?: LogomarkVariant;
  imageSx?: CSSProperties;
} & ImgProps;

const SmallFallback: FC<ImgProps> = ({ alt, ...props }) => (
  <img
    alt={alt || ''}
    src={getPublicImageUrl('/LuminaryLogo.svg')}
    {...props}
  />
);

const ImageContainer: FC<
  ImgProps & { src: string; imageSx?: CSSProperties }
> = ({ src, alt, imageSx, ...imgProps }) => (
  <img
    style={{ objectFit: 'contain', ...imageSx }}
    src={src}
    alt={alt || ''}
    {...imgProps}
  />
);

const LargeSecondaryFallback: FC<{
  alt?: string;
  size?: LogomarkSize;
}> = ({ alt, size = LogomarkSize.Wide }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <SmallFallback />
    <Fade
      unmountOnExit={false}
      appear={false}
      timeout={SIDEBAR_ANIMATION_TIMING_MS}
      in={size !== LogomarkSize.Square}
    >
      <Box
        display={size === LogomarkSize.Square ? 'none' : undefined}
        style={{ width: 100 }}
        ml={1}
        alt={alt || ''}
        component="img"
        src={getPublicImageUrl('/LuminaryText.svg')}
      />
    </Fade>
  </Box>
);

const LARGE_PRIMARY_FALLBACK_IMAGE =
  'https://lum.withluminary.com/assets/tenant-branding/lum/logo.svg';

export const isLogoImage = (url: string): boolean => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    diagnostics.warn(`Logo image URL not set for tenant`, {
      src: url,
      tenantSubdomain: getTenant(),
    });
    return false;
  }
};

function LogomarkInner({
  size,
  variant = LogomarkVariant.Primary,
  imageSx,
  ...imgProps
}: LuminaryLogomarkProps) {
  const theme = useTheme<LuminaryTheme>();

  switch (size) {
    case LogomarkSize.Square:
      switch (variant) {
        case LogomarkVariant.Primary:
          return <SmallFallback {...imgProps} />;
        case LogomarkVariant.Secondary:
          return theme.logos.secondarySquare ? (
            <ImageContainer
              src={theme.logos.secondarySquare}
              imageSx={imageSx}
              {...imgProps}
            />
          ) : (
            <LargeSecondaryFallback alt={imgProps.alt} size={size} />
          );
        default:
          throw new UnreachableError({
            case: variant,
            message: 'Invalid logomark size provided',
          });
      }
    case LogomarkSize.Wide:
      switch (variant) {
        case LogomarkVariant.Primary:
          return (
            <ImageContainer
              src={theme.logos.primaryWide || LARGE_PRIMARY_FALLBACK_IMAGE}
              imageSx={imageSx}
              {...imgProps}
            />
          );
        case LogomarkVariant.Secondary:
          return theme.logos.secondaryWide ? (
            <ImageContainer
              src={theme.logos.secondaryWide}
              imageSx={imageSx}
              {...imgProps}
            />
          ) : (
            <LargeSecondaryFallback alt={imgProps.alt} />
          );
        default:
          throw new UnreachableError({
            case: variant,
            message: 'Invalid logomark size provided',
          });
      }
    default:
      throw new UnreachableError({
        case: size,
        message: 'Invalid logomark size provided',
      });
  }
}

// this is really just so it can be used with the login page, which brings its own theme provider
export const LoginLogomark: FC<LuminaryLogomarkProps> = ({
  size,
  variant = LogomarkVariant.Primary,
  style,
  ...imgProps
}) => (
  <LogomarkInner size={size} variant={variant} style={style} {...imgProps} />
);

export const Logomark: FC<LuminaryLogomarkProps> = ({
  size,
  variant = LogomarkVariant.Primary,
  imageSx,
  ...imgProps
}) => (
  <CustomerThemeProvider>
    <LogomarkInner
      size={size}
      variant={variant}
      imageSx={imageSx}
      {...imgProps}
    />
  </CustomerThemeProvider>
);
