export function getPublicImageUrl(path: string) {
  if (path.startsWith('/')) {
    return `${
      process.env.NEXT_PUBLIC_PUBLIC_URL
        ? `${process.env.NEXT_PUBLIC_PUBLIC_URL}/`
        : '/'
    }images${path}`;
  }

  return `${
    process.env.NEXT_PUBLIC_PUBLIC_URL
      ? `${process.env.NEXT_PUBLIC_PUBLIC_URL}/`
      : '/'
  }images/${path}`;
}
