import { Box } from '@mui/material';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

import { Alert } from '../Alert/Alert';
import { NotificationSeverity } from '../constants';

export function createWrappedAlert(severity: NotificationSeverity) {
  const WrappedAlert = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ id, message }, ref) => {
      const { closeSnackbar } = useSnackbar();
      const handleDismiss = useCallback(() => {
        closeSnackbar(id);
      }, [closeSnackbar, id]);

      return (
        <SnackbarContent ref={ref} role="alert">
          <Box sx={{ boxShadow: 5, position: 'relative', maxWidth: 600 }}>
            <Alert size="lg" severity={severity} handleDismiss={handleDismiss}>
              {message}
            </Alert>
          </Box>
        </SnackbarContent>
      );
    }
  );
  WrappedAlert.displayName = `WrappedAlert-${severity}`;
  return WrappedAlert;
}
